<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('didactics.recovery_lesson_wizard.third_step_title') }}</div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-7">
        <card class="shadow-sm" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.recovery_lesson_wizard.lesson_data') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.code')" :value="lesson.code"/>
            <list-group-item-component :label="$t('fields.date')" :value="lesson.date | date"/>
            <list-group-item-component :label="$t('fields.time_start')" :value="lesson.time_start"/>
            <list-group-item-component :label="$t('fields.time_end')" :value="lesson.time_end"/>
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-5">
        <card class="shadow-sm" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.students') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-for="(student, index) in students"
              v-bind:key="`students-${index}`"
              :label="student.id | udid"
            >
              <template slot="value">
                <label-theme-component class="text-uppercase">
                  {{ student | optional('registry.surname') }} {{ student | optional('registry.name') }}
                </label-theme-component>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Lesson from "@/models/lesson";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "RecoveryLessonWizardThirdStep",
  components: {LabelThemeComponent, ListGroupItemComponent},
  props: {
    lesson: {
      type: Object,
      default: () => _.cloneDeep(Lesson)
    },
    students: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style scoped>

</style>
