<template>
  <ValidationObserver ref="lessonForm">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider
                  class="flex-grow-1"
                  name="code"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    class="mb-0"
                    type="text"
                    v-model="localLesson.code"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  />
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component  :label-width="labelWidth" :label="$t('common.status')">
              <template slot="value">
                <el-select
                  class="select-default text-uppercase"
                  value-key="code"
                  :placeholder="$t('common.type_to_search')"
                  v-model="localLesson.status"
                >
                  <el-option
                    v-for="option in lessonStatuses"
                    class="select-default text-uppercase"
                    :value="option.value"
                    :label="$t('didactics.lesson_status.' + option.value)"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.type')">
              <template slot="value">
                <div class="small text-uppercase" v-if="localLesson.type">
                  {{ $t('didactics.lesson_type.' + localLesson.type) }}
                </div>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.location')">
              <template slot="value">
                <div class="small text-uppercase">
                  {{ localLesson.location_id ? 'in presence' : 'virtual' }}
                </div>
              </template>
            </list-group-item-component>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.date')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider align-items-center
                  name="date"
                  class="flex-grow-1"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    class="mb-0"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-date-picker
                      type="date"
                      v-model="localLesson.date"
                      value-format="yyyy-MM-dd"
                      format="dd/MM/yyyy"
                      :placeholder="$t('common.select_day')"
                      :picker-options="{firstDayOfWeek: 1}"
                    />
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.time_start')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider
                  name="time_start"
                  class="flex-grow-1"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    class="mb-0"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      v-model="localLesson.time_start"
                      format="HH:mm"
                      value-format="HH:mm"
                      :placeholder="$t('fields.time_start')"
                      :picker-options="pickerOptions"/>
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.time_end')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider
                  name="time_end"
                  class="flex-grow-1"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    class="mb-0"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      v-model="localLesson.time_end"
                      :picker-options="pickerOptions"
                      format="HH:mm"
                      :placeholder="$t('fields.time_end')"
                      value-format="HH:mm"/>
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
          </ul>
        </div>
      </div>
      <div class="row" v-show="localLesson.status === lessonStatuses.completed.value">
        <div class="col-md-12" v-show="isTeachingManager">
          <title-section-component :label="$t('datatable.tutor')"/>
          <list-selector-component
            exclusive
            :number-items-per-page="12"
            :list="localTutors"
            ref="tutorSelector">
            <template v-slot:default="slotProps">
              <div class="text-uppercase text-truncate">
                {{ slotProps.item.lastname }} {{ slotProps.item.firstname }}
              </div>
            </template>
          </list-selector-component>
        </div>
        <div class="col-md-12">
          <title-section-component :label="$t('didactics.students')"/>
          <div class="row no-gutters">
            <div
              v-for="(student, index) in localStudents"
              v-bind:key="`localStudents-${index}`"
              class="col-12 col-md-6"
            >
              <el-card shadow="hover"
                       class="m-1 bg-transparent small"
                       :class="{'text-white': themeMode !== 'light'}"
                       :body-style="{ padding: '10px' }"
              >
                <div class="d-flex align-items-center mb-2">
                  <el-switch v-model="student.presence"/>
                  <div class="ml-2 flex-grow-1 text-uppercase text-truncate">
                    {{ student.name }}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <el-tooltip :content="$t('common.student_status')" :open-delay="300" placement="right">
                      <badge :type="student.status | studentStatusColor" class="text-uppercase w-100 text-truncate mb-0">
                        {{ $t('didactics.student_status.' + student.status) }}
                      </badge>
                    </el-tooltip>
                  </div>
                  <div class="col-md-4">
                    <el-tooltip
                      v-if="student.deal_status"
                      :content="$t('datatable.deal_status')"
                      :open-delay="300" placement="right"
                    >
                      <badge
                        v-if="student.deal_status"
                        :style="{backgroundColor: schedulerService.getDealStatusColor(student.deal_status)}"
                        class="text-uppercase w-100 text-truncate mb-0"
                      >
                        {{ $t('deal.status.' + student.deal_status) }}
                      </badge>
                    </el-tooltip>
                  </div>
                  <div class="col-md-4">

                    <el-tooltip
                      v-if="student.accounting_status"
                      :content="$t('datatable.accounting_status')"
                      :open-delay="300" placement="right"
                    >
                      <badge :type="dealAccountingStatus[student.accounting_status].color" class="text-uppercase w-100  text-truncate mb-0">
                        {{ $t('deal.accounting_statuses.' + student.accounting_status) }}
                      </badge>
                    </el-tooltip>

                </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col 12">
          <octo-notes
            @onSavedNotes="savedNotes"
            class="border-top"
            entity="lesson"
            :entity-id="localLesson.id"
            :notes="lesson.notes"
          />
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import Lesson from "@/models/lesson";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Card, DatePicker, Option, Select, Switch, TimeSelect} from "element-ui";
import Badge from "@/components/Badge";
import TitleSectionComponent from "@/components/TitleSectionComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import LessonStatuses from "@/pages/Didactics/resources/lessonStatuses";
import OctoNotes from "@/components/OctoNotes";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "ClassroomOnGoingEditLesson",
  components: {
    OctoNotes,
    ListSelectorComponent,
    TitleSectionComponent,
    Badge,
    ListGroupItemComponent,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Card.name]: Card,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      localLesson: this.$_.cloneDeep(Lesson),
      localTutors: [],
      localStudents: [],
      labelWidth: 120,
      pickerOptions: {
        start: '08:30',
        step: '00:15',
        end: '24:00'
      },
      permissionService: permissionService,
      lessonStatuses: LessonStatuses,
      dealAccountingStatus: DealAccountingStatus,
      schedulerService: schedulerService,
    }
  },
  props: {
    lesson: {
      type: Object,
      default: () => this.$_.cloneDeep(Lesson)
    },
    tutors: {
      type: Array,
      default: () => []
    },
    students: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      themeMode: 'auth/themeMode',
      currentUser: 'auth/currentUser'
    }),

    isPast() {
      const date = this.$moment(this.lesson.date, 'YYYY-MM-DD');
      return date.isValid() ? !date.isAfter() : false;
    },

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },
  },
  mounted() {
    this.localLesson = this.$_.cloneDeep(this.lesson);
    this.localTutors = this.$_.cloneDeep(this.tutors);

    this.$refs.tutorSelector.itemSelected = this.localLesson.tutor_id;

    const tempStudent = this.localLesson.students && this.localLesson.students.length > 0
      ? this.localLesson.students
      : this.$_.cloneDeep(this.students);

    this.localStudents = this.$_.map(tempStudent, (student) => {
      return {
        id: student.id,
        status: student.status,
        deal_status: student?.deal?.status,
        accounting_status: student?.deal?.accounting_status,
        name: student?.registry?.surname + ' ' + student?.registry?.name,
        presence: student?.lessonPresence || false,
      }
    });

  },
  methods: {
    savedNotes(notes) {
      this.localLesson.notes = notes;
      this.$emit('onSavedNotes', notes)
    },
    getLessonData() {
      return new Promise((resolve, reject) => {
        this.$refs.lessonForm.validate()
          .then((success) => {

            const lessonData = {
              lesson: {
                code: this.localLesson.code,
                date: this.localLesson.date,
                time_start: this.localLesson.time_start,
                time_end: this.localLesson.time_end,
                status: this.localLesson.status
              },
              tutor_id: null,
              students: null
            };

            if (this.localLesson.status === this.lessonStatuses.completed.value) {
              lessonData.tutor_id = this.isTeachingManager
                ? this.$refs.tutorSelector.getSelectedItem()
                : this.currentUser.id;

              lessonData.students = this.localStudents;

              if (!lessonData.tutor_id) {
                this.$notify({type: 'danger', message: this.$t('notifications.tutor_not_selected')});
                success = false;
              }

              if (!this.$_.some(this.localStudents, {presence: true})) {
                this.$notify({type: 'danger', message: this.$t('notifications.no_student_presence')});
                success = false;
              }

            }

            if (this.localLesson.status === LessonStatuses.cancelled.value && this.$_.isEmpty(this.localLesson.notes)) {
              this.$notify({type: 'danger', message: this.$t('notifications.no_note_presence')});
              success = false;
            }
            if(success) {
              resolve(lessonData);
            } else {
              reject()
            }

          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
