<template>
  <simple-wizard
    @tab-change="tabChange"
    @onCompleted="completeWizard"
    :finishButtonText="$t('common.finish')"
    :nextButtonText="$t('common.next')"
    :prevButtonText="$t('common.prev')"
  >
    <template slot="header">
      <h4 class="description">{{ $t('didactics.recovery_lesson_wizard.title') }}</h4>
    </template>

    <wizard-tab :before-change="() => validateStep(1)">
      <template slot="label">
        <octo-icon icon="list"></octo-icon>
        <p>{{ $t('didactics.recovery_lesson_wizard.lesson_data') }}</p>
      </template>
      <recovery-lesson-wizard-first-step ref="recoveryLessonWizardFirstStep"/>
    </wizard-tab>

    <wizard-tab :before-change="() => validateStep(2)">
      <template slot="label">
        <octo-icon icon="filter"></octo-icon>
        <p>{{ $t('didactics.recovery_lesson_wizard.students') }}</p>
      </template>
      <recovery-lesson-wizard-second-step ref="recoveryLessonWizardSecondStep"/>
    </wizard-tab>

    <wizard-tab :before-change="() => validateStep(2)">
      <template slot="label">
        <octo-icon icon="checked"></octo-icon>
        <p>{{ $t('didactics.recovery_lesson_wizard.report') }}</p>
      </template>
      <recovery-lesson-wizard-third-step ref="recoveryLessonWizardThirdStep" :lesson="lesson" :students="students"/>
    </wizard-tab>
  </simple-wizard>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Lesson from "@/models/lesson";
import RecoveryLessonWizardFirstStep from "@/pages/Didactics/recovery-lesson-wizard/RecoveryLessonWizardFirstStep";
import RecoveryLessonWizardSecondStep from "@/pages/Didactics/recovery-lesson-wizard/RecoveryLessonWizardSecondStep";
import RecoveryLessonWizardThirdStep from "@/pages/Didactics/recovery-lesson-wizard/RecoveryLessonWizardThirdStep";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "RecoveryLessonWizard",
  components: {
    OctoIcon,
    WizardTab,
    SimpleWizard,
    RecoveryLessonWizardFirstStep,
    RecoveryLessonWizardSecondStep,
    RecoveryLessonWizardThirdStep,
  },
  data() {
    return {
      lesson: _.cloneDeep(Lesson),
      students: [],
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    })
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const data = await this.$refs.recoveryLessonWizardFirstStep.validate();
          if (data) {
            this.lesson = data;
            check = true;
          }
          break;
        case 2:
          const students = await this.$refs.recoveryLessonWizardSecondStep.getStudents();
          if (students && students.length > 0) {
            this.students = students;
            check = true;
          }
          break;
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    tabChange(oldTab, newTab) {
      switch (newTab.tabId) {
        case '0':
        case '1':
        case '2':
        default:
          break;
      }
    },
    completeWizard() {
      this.$fullLoading.show();
      this.$api.post(
        endpoints.CLASSROOM_STORE_ON_GOING_RECOVERY_LESSON.replace('{id}', this.classroom.id),
        {...this.lesson, studentIds: this.$_.map(this.students, student => student.id)}
      )
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
          this.$emit('onCloseWizardModal');
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
      .finally(() => {
        this.$fullLoading.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
