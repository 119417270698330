<template>
  <div class="container-fluid">
    <ValidationObserver ref="lessonForm">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider class="flex-grow-1" name="code" rules="required"
                                    v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="lessonLocal.code"
                    class="mb-0"
                    type="text"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.date')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider name="date" class="flex-grow-1" rules="required"
                                    v-slot="{ passed, failed, errors }">
                  <base-input class="mb-0"
                              :error="errors[0]"
                              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
                  >
                    <el-date-picker
                      type="date"
                      v-model="lessonLocal.date"
                      value-format="yyyy-MM-dd"
                      format="dd/MM/yyyy"
                      :placeholder="$t('common.select_day')"
                      :picker-options="{firstDayOfWeek: 1}"
                    />
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.time_start')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider name="time_start"
                                    class="flex-grow-1"
                                    rules="required"
                                    v-slot="{ passed, failed, errors }">
                  <base-input class="mb-0"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      v-model="lessonLocal.time_start"
                      format="HH:mm"
                      value-format="HH:mm"
                      :clearable="false"
                      :picker-options="pickerOptions"
                    />
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.time_end')" :label-width="labelWidth">
              <template slot="value">
                <ValidationProvider name="time_end"
                                    class="flex-grow-1"
                                    rules="required"
                                    v-slot="{ passed, failed, errors }">
                  <base-input class="mb-0"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      v-model="lessonLocal.time_end"
                      format="HH:mm"
                      value-format="HH:mm"
                      :clearable="false"
                      :picker-options="pickerOptions"
                    />
                  </base-input>
                </ValidationProvider>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('common.location')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="mb-0 flex-grow-1">
                  <el-select
                    class="select-default text-uppercase"
                    filterable
                    clearable
                    value-key="id"
                    :placeholder="$t('common.virtual')"
                    v-model="lessonLocal.location_id"
                  >
                    <el-option
                      class="select-default text-uppercase"
                      v-for="(location, index) in locations"
                      :value="location.id"
                      :label="location.alias"
                      :key="`location-${index}`"
                    />
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </div>
      </div>
    </ValidationObserver>
  </div>


</template>

<script>
import {Select, Option, DatePicker, TimeSelect} from 'element-ui';
import Lesson from "@/models/lesson";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "LessonForm",
  components: {
    BaseTextArea,
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      lessonLocal: Lesson,
      labelWidth: 100
    }
  },
  props: {
    lesson: {
      type: Object,
      default: () => _.cloneDeep(Lesson)
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.lessonLocal = this.$_.cloneDeep(this.lesson);
  },
  computed: {
    ...mapGetters({
      settings: "common/settings",
      locations: "common/locations"
    }),

    pickerOptions() {
      return {
        start: this.$options.filters.padStart(this.settings?.callcenter_calendar_start, 2) + ':00',
        end: this.$options.filters.padStart(this.settings?.callcenter_calendar_end, 2) + ':00',
        step: '00:30',
      }
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.lessonForm.validate()
          .then((success) => {
            if (success) {
              resolve(this.lessonLocal);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
}
</script>

<style scoped>

</style>
