<template>
  <div class="row">
    <div class="col-md-12">
      <card body-classes="standard-card-body" class="shadow mb-0">
        <div slot="header">
          <h4 class="card-title text-capitalize">{{ $t('didactics.register') }}</h4>
        </div>
        <el-table stripe :data="register">
          <el-table-column
            fixed
            :min-width="250"
            align="left"
          >
            <div slot-scope="{ row }">
              <div class="text-uppercase text-truncate">
                {{ row.student | optional('registry.surname') }} {{ row.student | optional('registry.name') }}
              </div>
            </div>
          </el-table-column>
          <el-table-column align="center" :width="160">
            <template slot="header">{{ $t('common.status') }}</template>
            <div slot-scope="{ row }">
              <badge :type="row.student.status | studentStatusColor" class="text-uppercase">
                {{ $t('didactics.student_status.' + row.student.status) }}
              </badge>
            </div>
          </el-table-column>
          <el-table-column align="center" :width="150">
            <template slot="header">{{ $t('datatable.accounting_status') }}</template>
            <div slot-scope="{ row }">
              <badge v-if="row.student.deal"
                :type="dealAccountingStatus[row.student.deal.accounting_status].color"
                class="text-uppercase"
              >
                {{ $t('deal.accounting_statuses.' + row.student.deal.accounting_status) }}
              </badge>
            </div>
          </el-table-column>
          <el-table-column
            v-for="(lesson, index) in lessons" v-bind:key="`lesson-${index}`"
            align="center"
            :width="70"
          >
            <template slot="header"> {{ lesson.date | date('DD/MM') }}</template>
            <div slot-scope="{ row }">
              <div v-if="row.lessons.hasOwnProperty(lesson.id)">
                <div v-if="row.lessons[lesson.id] === 'P'">
                  <octo-icon class="text-success" icon="checked"/>
                </div>
                <div v-if="row.lessons[lesson.id] === 'A'">
                  <octo-icon class="text-muted" icon="wrong"/>
                </div>
              </div>
            </div>
          </el-table-column>
          <el-table-column fixed="right" :width="60">
            <div slot-scope="{ row }">
              <base-button
                link
                icon
                size="sm"
                class="ml-auto text-capitalize my-0"
                @click="$router.push({name: 'students.archive.show', params: {id: row.student.id}})"
              >
                <octo-icon icon="magnifying-glass"/>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Table, TableColumn} from "element-ui";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";
import Badge from "@/components/Badge";

export default {
  name: "ClassroomOnGoingRegister",
  components: {
    Badge,
    OctoIcon,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      register: [],
      lessons: {},
      dealAccountingStatus: DealAccountingStatus,
      schedulerService: schedulerService,
    }
  },
  watch: {
    classroom: {
      handler(val) {
        this.lessons = this.$_.keyBy(this.$_.sortBy(this.classroom.lessons, 'date'), 'id');
        const items = this.$_.map(this.classroom.students, (student) => {
          const data = {
            student: student,
            lessons: {}
          }

          this.$_.each(student.lessons, (lesson) => {
            data.lessons[lesson.id] = !!lesson?.studentPresence ? 'P' : 'A';
          });

          return data;
        });

        this.register = this.$_.sortBy(items, (item) => {
          return item?.student?.registry?.surname || '';
        });
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    }),
  },
}
</script>

<style scoped>

</style>
