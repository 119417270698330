<template>
  <card class="shadow" body-classes="standard-card-body t-0">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('fields.certificates') }}</h4>
    </div>
    <div class="row px-2 d-flex justify-content-center">
      <div
        class="col-12 py-2"
        :class="{'border-top': indexCertificate !== 0}"
        v-for="(certificate, indexCertificate) in certificates"
        v-bind:key="`certificate-${indexCertificate}`"
      >
        <h4 class="card-title">
          <label-theme-component>{{ certificate | optional('code') }}:</label-theme-component>
          {{ certificate | optional('title') }}
        </h4>
        <ul class="list-group list-group-flush">
          <li class="list-group-item text-center" v-if="certificate.students.length === 0">
            {{ $t('common.empty_list') }}
          </li>
        </ul>
        <div class="row">
          <div class="col-12 col-md-3"
               v-for="(students, indexList) in certificate.students"
               v-bind:key="`student-list-${indexList}`"
          >
            <ul class="list-group list-group-flush">
              <list-group-item-component
                v-for="student in students"
                v-bind:key="`student-${indexList}-${student.id}`"
              >
                <template slot="label">
                  <div class="standard-label text-truncate">
                    {{ student | optional('registry.surname') }}
                    {{ student | optional('registry.name') }}
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";

export default {
  name: "ClassroomOnGoingStudentsByCertificate",
  components: {LabelThemeComponent, ListGroupItemComponent},
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses',
    }),

    certificates() {
      return this.$_.map(this.classroom.courses, certificate => {
        const students = this.$_.filter(this.classroom.students, student => student.course_id === certificate.id);
        certificate.students = this.$_.chunk(students, this.$_.ceil(students.length / 4));
        return certificate;
      });
    }
  }
}
</script>

<style scoped>

</style>
