<template>
  <list-selector-component ref="listSelectorComponent" :number-items-per-page="12" :list="localCertificates">
    <template v-slot:default="slotProps">
      <div class="standard-label text-truncate">
        <label-theme-component>{{ courses[slotProps.item.id] | optional('code') }}</label-theme-component>
        {{ courses[slotProps.item.id] | optional('title') }}
      </div>
    </template>
  </list-selector-component>
</template>

<script>
import {mapGetters} from "vuex";
import Registry from "@/models/registry";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ClassroomOnGoingStudentsCertificatesList",
  components: {LabelThemeComponent, ListSelectorComponent},
  data() {
    return {
      localCertificates: []
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => _.cloneDeep(Registry)
    },
  },
  mounted() {
    this.localCertificates = this.$_.map(
      this.$_.cloneDeep(this.availableCertificates),
      (item) => {
        item.selected = !!this.$_.find(
          this.registry?.enabled_certifications,
          certification => certification.id === item.id
        );
        return {id: item.id, selected: item.selected};
      }
    );
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses',
      certificates: 'common/certificates'
    }),

    availableCertificates() {
      const certificates = [];

      this.$_.each(this.certificates, certificate => {
        if (!!this.$_.find(certificate.courses, course => course.id === this.classroom.courses?.[0]?.id)) {
          certificates.push(certificate)
        }
      });

      return certificates;
    }
  },
  methods: {
    getEnabledCertificates() {
      return this.$refs.listSelectorComponent.getAllItems();
    }
  }
}
</script>

<style scoped>

</style>
