var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"shadow mb-0",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.register')))])]),_c('el-table',{attrs:{"stripe":"","data":_vm.register}},[_c('el-table-column',{attrs:{"fixed":"","min-width":250,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-uppercase text-truncate"},[_vm._v(" "+_vm._s(_vm._f("optional")(row.student,'registry.surname'))+" "+_vm._s(_vm._f("optional")(row.student,'registry.name'))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"center","width":160},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm._f("studentStatusColor")(row.student.status)}},[_vm._v(" "+_vm._s(_vm.$t('didactics.student_status.' + row.student.status))+" ")])],1)}}])},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.$t('common.status')))])],2),_c('el-table-column',{attrs:{"align":"center","width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.student.deal)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.dealAccountingStatus[row.student.deal.accounting_status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.accounting_statuses.' + row.student.deal.accounting_status))+" ")]):_vm._e()],1)}}])},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.$t('datatable.accounting_status')))])],2),_vm._l((_vm.lessons),function(lesson,index){return _c('el-table-column',{key:("lesson-" + index),attrs:{"align":"center","width":70},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.lessons.hasOwnProperty(lesson.id))?_c('div',[(row.lessons[lesson.id] === 'P')?_c('div',[_c('octo-icon',{staticClass:"text-success",attrs:{"icon":"checked"}})],1):_vm._e(),(row.lessons[lesson.id] === 'A')?_c('div',[_c('octo-icon',{staticClass:"text-muted",attrs:{"icon":"wrong"}})],1):_vm._e()]):_vm._e()])}}],null,true)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm._f("date")(lesson.date,'DD/MM')))])],2)}),_c('el-table-column',{attrs:{"fixed":"right","width":60},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('base-button',{staticClass:"ml-auto text-capitalize my-0",attrs:{"link":"","icon":"","size":"sm"},on:{"click":function($event){return _vm.$router.push({name: 'students.archive.show', params: {id: row.student.id}})}}},[_c('octo-icon',{attrs:{"icon":"magnifying-glass"}})],1)],1)}}])})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }