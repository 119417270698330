<template>
  <div>
    <p class="text-uppercase">{{ $t('common.summary') }}</p>
    <div class="ml-2 text-muted">
      Riepilogo della situazione generale dell'aula. Sono presenti i dati dell'aula, il registro degli studenti e
      la gestione delle note.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save')">
        <div>
          Salva dati generali dell'aula.
        </div>
      </collapse-item>
      <collapse-item
        :title="$t('common.close_classroom')"
        v-if="classroom.status === classroomStatuses.on_going.value"
      >
        <div>
          Imposta l'aula nello stato {{ $t('didactics.classroom_status.closed') }} e gli studenti che stanno frequentando nello stato
          {{ $t('didactics.student_status.completed') }}.
          Verranno cancellate le lezioni future e non completate.
          Dopo sarà possibile comunque aggiornare le presenze e il programma.
          Non sarà possibile aggiungere più lezioni a meno che non venga riattivata.
        </div>
      </collapse-item>
      <collapse-item
        :title="$t('common.interrupt_classroom')"
        v-if="classroom.status === classroomStatuses.on_going.value"
      >
        <div>
          Imposta l'aula nello stato {{ $t('didactics.classroom_status.interrupted') }} e non aggiorna lo stato degli studenti.
          Non Verranno cancellate le lezioni future e non completate.
        </div>
      </collapse-item>
      <collapse-item
        :title="$t('common.reactivate_classroom')"
        v-if="classroom.status === classroomStatuses.closed.value"
      >
        <div>
          Imposta l'aula nello stato {{ $t('didactics.classroom_status.on_going') }} e gli studenti con lo stato {{ $t('didactics.student_status.completed') }} di nuovo
          come frequentanti.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('fields.lessons') }}</p>
    <div class="ml-2 text-muted">
      Elenco delle lezione dell'aula corrente. Ogni lezione può essere aggiornata o cancellata.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.add_lesson')">
        <div>
          Apri modale per aggiungere una nuova lezione.
        </div>
      </collapse-item>
      <collapse-item :title="$t('common.create_recovery_lesson')">
        <div>
          Apri modale per la creazione di una nuova lezione di recupero tramite un wizard apposito.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('didactics.program') }}</p>
    <div class="ml-2 text-muted">
      Vista generale del programma dell'aula. È possible assegnare a ogni argomento del corso le lezioni dove sarà
      svolto.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save_program')">
        <div>
          Salva il programma corrente.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('fields.tutors') }}</p>
    <div class="ml-2 text-muted">
      Elenco dei docenti disponibili e associati all'aula.
    </div>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('common.save_tutors')">
        <div>
          Associa all'aula i docenti selezionati.
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-2">{{ $t('common.certifications_management') }}</p>
    <div class="ml-2 text-muted">
      Elenco degli studenti tramite il quale si possono scegliere le certificazioni abilitati per ognuno.
    </div>
  </div>
</template>

<script>
import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import {mapGetters} from "vuex";

export default {
  name: "ShowClassroomPendingPageHelper",
  components: {CollapseItem, Collapse},
  data() {
    return {
      classroomStatuses: classroomStatuses
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing'
    }),
  }
}
</script>

<style scoped>

</style>
