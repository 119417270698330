<template>
  <ValidationObserver ref="lessonGenerator">
    <ul class="list-group list-group-flush">
      <list-group-item-component
        :label="$t('fields.code')"
        :label-width="labelWidth"
      >
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="date"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input required
                        v-model="lessonPrefix"
                        class="mb-0"
                        addonRightText="-YYYYMMDD"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('fields.date_start')"
        :label-width="labelWidth"
      >
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="date"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input required
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
              <el-date-picker
                type="date"
                v-model="date"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('common.select_day')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('fields.lesson_number')"
        :label-width="labelWidth"
      >
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="lesson_number"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="mb-0"
              min="1"
              v-model="lessonNumber"
              type="number"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('fields.time_start')"
        :label-width="labelWidth"
      >
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="time_start"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input required
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
              <el-time-select
                v-model="timeStart"
                format="HH:mm"
                value-format="HH:mm"
                :placeholder="$t('fields.time_start')"
                :picker-options="pickerOptions"/>
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('fields.time_end')"
        :label-width="labelWidth"
      >
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="time_end"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input required
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
              <el-time-select
                v-model="timeEnd"
                :picker-options="pickerOptions"
                format="HH:mm"
                :placeholder="$t('fields.time_end')"
                value-format="HH:mm"/>
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-title-section-component :label="$t('fields.week_days')"/>
      <list-group-item-component>
        <template slot="label">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6" v-for="weekDay in weekDays"
                   v-bind:key="`day-${weekDay.value}`">
                <el-card class="m-1" shadow="hover" :body-style="{ padding: '10px' }">
                  <div class="d-flex align-items-center">
                    <div>
                      <el-switch v-model="weekDay.selected"/>
                    </div>
                    <div class="ml-2 flex-grow-1 text-uppercase">
                      {{ weekDay.label }}
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>

import {Select, Option, DatePicker, TimeSelect, Switch, Card} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseAlert from "@/components/BaseAlert";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import {mapGetters} from "vuex";
import Lesson from "@/models/lesson";
import Classroom from "@/models/classroom";

require('moment-recur');

export default {
  name: "LessonGenerator",
  components: {
    ListGroupTitleSectionComponent,
    BaseAlert,
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Card.name]: Card,
    [Switch.name]: Switch
  },
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD'),
      timeStart: null,
      timeEnd: null,
      lessonNumber: 1,
      lessonPrefix: '',
      labelWidth: 140,
      pickerOptions: {
        start: '08:30',
        step: '00:15',
        end: '24:00'
      },
      weekDays: [
        {label: this.$t('common.mon-full'), value: 1, selected: false},
        {label: this.$t('common.tue-full'), value: 2, selected: false},
        {label: this.$t('common.wed-full'), value: 3, selected: false},
        {label: this.$t('common.thu-full'), value: 4, selected: false},
        {label: this.$t('common.fri-full'), value: 5, selected: false},
        {label: this.$t('common.sat-full'), value: 6, selected: false},
        {label: this.$t('common.sun-full'), value: 7, selected: false},
      ]
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.lessonGenerator.validate()
          .then((success) => {
            if (this.selectedWeekDays.length === 0) {
              this.$notify({type: 'danger', message: this.$t('notifications.days_not_selected')});
            } else if (success) {
              resolve(this.generateLessons());
            }
          })
          .catch((err) => {
            reject(err);
          });
      });

    },

    generateLessons() {
      const dateRecurrence = this.$moment(this.date).recur().every(this.selectedWeekDays).daysOfWeek();

      const dates = dateRecurrence.next(this.lessonNumber);

      if (dateRecurrence.matches(this.date)) {
        dates.unshift(this.$moment(this.date));
        dates.pop();
      }

      return this.$_.map(dates, date => {
        return {
          date: date.format('YYYY-MM-DD'),
          code: this.lessonPrefix + '-' + date.format('YYYYMMDD'),
          time_start: this.timeStart,
          time_end: this.timeEnd,
          location_id: null
        }
      });
    }
  },
  props: {
    classroom: {
      type: Object,
      default: () => this.$_.cloneDeep(Classroom)
    },
  },
  computed: {

    selectedWeekDays() {
      return this.$_.map(this.$_.filter(this.weekDays, item => item.selected), item => item.value);
    }
  },
  watch: {
    classroom: function (val) {
      this.lessonNumber = this.$_.cloneDeep(this.classroom?.courses?.[0]?.lesson_number);
    },
  },
  mounted() {
    this.lessonPrefix = this.classroom.code;
  }
}
</script>

<style scoped>

</style>
