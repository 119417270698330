<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('didactics.recovery_lesson_wizard.first_step_title') }}</div>
      </div>
    </div>
    <certificate-lesson-form ref="lessonForm"/>
  </div>
</template>

<script>
import Lesson from "@/models/lesson";
import CertificateLessonForm from "@/pages/Didactics/forms/CertificateLessonForm";

export default {
  name: "RecoveryLessonWizardFirstStep",
  components: {CertificateLessonForm},
  data() {
    return {
      lesson: this.$_.cloneDeep(Lesson)
    }
  },
  methods: {
    validate() {
      return this.$refs.lessonForm.validate();
    }
  }
}
</script>

<style scoped>

</style>
