<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow mb-2" body-classes="standard-card-body py-0">
        <template slot="header">
          <h4 class="card-title text-capitalize">{{ $t('fields.courses') }}</h4>
        </template>
        <div class="row">
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component
                v-for="(course, index) in courseChunks[0]"
                v-bind:key="`course-1-${index}`"
              >
                <template slot="label">
                  <div class="standard-label text-truncate">
                    <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                    {{ course.title }}
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </div>
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component
                v-for="(course, index) in courseChunks[1]"
                v-bind:key="`course-2-${index}`"
              >
                <template slot="label">
                  <div class="standard-label text-truncate">
                    <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                    {{ course.title }}
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ClassroomOnGoingCoursesRecap",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    }),

    courseChunks() {
      return this.$_.chunk(this.classroom.courses, this.$_.ceil(this.classroom.courses.length / 2));
    }
  }
}
</script>

<style scoped>

</style>
