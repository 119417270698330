<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="classroom.code"
          :buttons="headerButtons"
          @onOpenRecoveryLessonWizard="openRecoveryLessonWizard"
          @onAddLesson="addLesson"
          @onScheduleLessons="scheduleLessons"
        />
      </div>
    </div>
    <classroom-on-going-lessons-recap/>
    <div class="row">
      <div class="col-md-12">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <el-table stripe :data="lessons">
            <el-table-column
              fixed
              align="center"
              :label="$t('datatable.date')"
              :width="150"
            >
              <div slot-scope="{ row }">
                <label-theme-component class="text-uppercase">{{ row.date | day('short') }}</label-theme-component>
                {{ row.date | date }}
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('datatable.time')"
              :width="150"
            >
              <div slot-scope="{ row }">
                <small>{{ row.time_start | time }} - {{ row.time_end | time }}</small>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('datatable.code')"
              :width="250"
            >
              <div slot-scope="{ row }">
                <div class="text-uppercase small">{{ row.code }}</div>
              </div>
            </el-table-column>
            <el-table-column
              :label="$t('datatable.status')"
              :width="150"
              align="center">
              <div slot-scope="{ row }">
                <badge :type="row.status | lessonStatusColor" class="text-uppercase">
                  {{ $t('didactics.lesson_status.' + row.status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('fields.type')"
              :width="150"
            >
              <div slot-scope="{ row }">
                <div class="text-uppercase small">{{ $t('didactics.lesson_type.' + row.type) }}</div>
              </div>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('common.location')"
              :width="150"
            >
              <div slot-scope="{ row }">
                <div class="text-uppercase small">{{ getLocationName(row.location_id) }}</div>
              </div>
            </el-table-column>
            <el-table-column
              :label="$t('datatable.tutor')"
              :min-width="180"
              align="center">
              <div slot-scope="{ row }">
                <div v-if="row.tutor_id" class="text-uppercase">
                  {{ users[row.tutor_id] | optional('lastname') }}
                </div>
              </div>
            </el-table-column>
            <el-table-column
              :label="$t('datatable.presence')"
              :width="100"
              align="center">
              <div slot-scope="{ row }">
                {{ row.presences_count }}
              </div>
            </el-table-column>
            <el-table-column
              :width="100"
              align="right"
              fixed="right"
            >
              <div slot-scope="{ row }">
                <base-button
                  size="sm"
                  link
                  @click="openModal(row)"
                  icon>
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button
                  v-if="isTeachingManager"
                  size="sm"
                  link
                  @click="deleteLesson(row)"
                  icon
                >
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <modal centered :show.sync="showModal" bodyClasses="p-1" modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title">{{ selectedLesson.code }}</h5>
      </template>
      <classroom-on-going-edit-lesson
        :lesson="selectedLesson"
        :tutors="classroom.tutors"
        :students="classroom.students"
        :key="modalKey"
        @onSavedNotes="updateLessonNotes"
        ref="classroomOnGoingEditLessonRef"
      />
      <template slot="footer">
        <base-button link class="text-capitalize" @click="updateLesson">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
    <modal centered bodyClasses="p-1" modalClasses="modal-xl" :show.sync="showModalWizard">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('didactics.recovery_lesson_wizard.title') }}
      </h5>
      <recovery-lesson-wizard
        ref="recoveryLessonWizard"
        :key="`wizard-${modalKey}`"
        @onCloseWizardModal="showModalWizard = false;"
      />
    </modal>
    <modal :show.sync="showModalForm" bodyClasses="px-1" centered>
      <template slot="header">
        <h5 class="modal-title text-uppercase">
          {{  $t('didactics.add_lesson') }}
        </h5>
      </template>
      <lesson-form :key="modalKey" ref="lessonForm"/>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="storeLesson">
          {{ $t('common.add') }}
        </base-button>
      </template>
    </modal>
    <modal bodyClasses="px-1" :show.sync="showGeneratorModal" centered>
      <template slot="header">
        <h5 class="modal-title text-uppercase">
          {{ $t('didactics.generate_calendar') }}
        </h5>
      </template>
      <lesson-generator :key="modalKey" ref="lessonGenerator" :classroom="classroom"/>
      <template slot="footer">
        <base-button confirm-required
                     class="text-uppercase"
                     link
                     :confirm-title="$t('confirm.generate_lessons_title')"
                     :confirm-text="$t('confirm.generate_lessons_text')"
                     @click="overwriteLessons"
        >
          {{ $t('common.update') }}
        </base-button>

      </template>
    </modal>

  </div>
</template>

<script>

import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import {MessageBox, Table, TableColumn} from "element-ui";
import Badge from "@/components/Badge";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import Lesson from "@/models/lesson";
import ClassroomOnGoingEditLesson from "@/pages/Didactics/components/ClassroomOnGoingEditLesson";
import {endpoints} from "@/routes/endpoints";
import RecoveryLessonWizard from "@/pages/Didactics/recovery-lesson-wizard/RecoveryLessonWizard";
import {permissionService} from "@/util/permission-service";
import LessonForm from "@/pages/Didactics/forms/LessonForm";
import ClassroomStatuses from "../resources/classroomStatuses";
import LabelThemeComponent from "../../../components/LabelThemeComponent";
import ClassroomOnGoingLessonsRecap from "./ClassroomOnGoingLessonsRecap";
import LessonGenerator from "@/pages/Didactics/components/LessonGenerator.vue";
import OctoNotes from "@/components/OctoNotes.vue";

export default {
  name: "ClassroomOnGoingLessons",
  components: {
    OctoNotes,
    ClassroomOnGoingLessonsRecap,
    LabelThemeComponent,
    LessonForm,
    LessonGenerator,
    RecoveryLessonWizard,
    ClassroomOnGoingEditLesson,
    OctoIcon,
    Badge,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,

  },
  data() {
    return {
      lessons: [],
      showModal: false,
      showModalWizard: false,
      showModalForm: false,
      showGeneratorModal: false,
      selectedLesson: this.$_.cloneDeep(Lesson),
      modalKey: 1
    }
  },

  watch: {
    classroom: {
      handler(val) {
        this.lessons = this.$_.sortBy(this.classroom.lessons, 'date');
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      classroom: 'current/classroomOnGoing',
      location: 'common/locations',
    }),

    headerButtons() {
      const buttons = [];

      if (this.isTeachingManager && this.classroom.status !== ClassroomStatuses.closed.value) {
        buttons.push({
          label: 'add_lesson',
          onClick: 'onAddLesson'
        });
        buttons.push({
          label: 'schedule_lessons',
          onClick: 'onScheduleLessons'
        });
        buttons.push({
          label: 'create_recovery_lesson',
          onClick: 'onOpenRecoveryLessonWizard'
        });
      }

      return buttons;
    },

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    }
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    openModal: function (lesson) {
      this.selectedLesson = this.$_.cloneDeep(lesson);
      this.modalKey++;
      this.showModal = true;
    },

    openRecoveryLessonWizard: function () {
      this.modalKey++;
      this.showModalWizard = true;
    },

    addLesson() {
      this.modalKey++;
      this.showModalForm = true;
    },

    scheduleLessons() {
      MessageBox({
        title: this.$t('confirm.confirm_title'),
        message: this.$t('confirm.confirm_schedule_lessons'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.modalKey++;
          this.showGeneratorModal = true;
        })
        .catch(() => {
        })


    },

    overwriteLessons: async function() {
      try {
        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.CLASSROOM_SYNC_LESSONS_ON_GOING.replace('{id}', this.classroom.id),
          {lessons: await this.$refs.lessonGenerator.validate()}
        );
        this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data});
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        this.showGeneratorModal = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    updateLesson: async function () {
      try {
        const lessonData = await this.$refs.classroomOnGoingEditLessonRef.getLessonData();

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.CLASSROOM_UPDATE_ON_GOING_LESSON
            .replace('{id}', this.classroom.id)
            .replace('{lessonId}', this.selectedLesson.id),
          lessonData
        );

        this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
        this.setPartialClassroomOnGoing({key: 'students', value: resp?.data?.data?.students});
        this.showModal = false;
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    updateLessonNotes: async function (notes) {

      const lessons = this.$_.map(this.classroom.lessons, (lesson) => {
        if(lesson.id === this.selectedLesson.id) {
          lesson.notes = notes;
        }
        return lesson;
      });

      this.setPartialClassroomOnGoing({key: 'lessons', value: lessons});
    },

    storeLesson: async function () {
      try {
        const lessonData = await this.$refs.lessonForm.validate();

        this.$fullLoading.show();

        const resp = await this.$api.post(
          endpoints.CLASSROOM_STORE_ON_GOING_LESSON.replace('{id}', this.classroom.id),
          lessonData
        );

        this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
        this.showModalForm = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    deleteLesson(lesson) {
      MessageBox({
        title: this.$t('confirm.confirm_title'),
        message: this.$t('confirm.delete_lesson_text'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.$fullLoading.show();

          this.$api.delete(
            endpoints.CLASSROOM_DELETE_ON_GOING_LESSON
              .replace('{id}', this.classroom.id)
              .replace('{lessonId}', lesson.id)
          )
            .then((resp) => {
              this.setPartialClassroomOnGoing({key: 'lessons', value: resp?.data?.data?.lessons});
            })
            .catch(() => {
              this.$notify({type: 'danger', message: this.$t('notifications.deleted_error')});
            })
            .finally(() => {
              this.$fullLoading.hide();
            })
        })
        .catch(() => {
        })
    },

    getLocationName(locationId) {
      if (!locationId) {
        return this.$t('common.virtual');
      }

      return this.$_.find(this.locations, location => location.id === locationId)?.alias;
    }
  }
}
</script>

<style scoped>
</style>
