<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="classroom.code"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <el-table stripe :data="localStudents">
            <el-table-column :label="$t('datatable.student')" :width="300" align="left">
              <div slot-scope="{ row }">
                <div class="text-uppercase text-truncate">
                  {{ row | optional('registry.surname') }} {{ row | optional('registry.name') }}
                </div>
              </div>
            </el-table-column>
            <el-table-column :min-width="100" :label="$t('common.enabled_certifications')">
              <div slot-scope="{ row }">
                <div class="text-uppercase small">{{ getEnabledCertificationsCodes(row.registry) }}</div>
              </div>
            </el-table-column>
            <el-table-column :width="100" align="right">
              <div slot-scope="{ row }">
                <base-button link icon size="sm" class="my-0" @click="openModal(row)">
                  <octo-icon icon="edit"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <modal centered bodyClasses="p-1" :show.sync="showModal" modalClasses="modal-lg">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ `${selectedStudent.registry.surname} ${selectedStudent.registry.name} - ` }}
        {{ $t('common.enabled_certifications') }}
      </h5>
      <classroom-on-going-students-certificates-list
        ref="studentEnabledCertificatesList"
        :registry="selectedStudent.registry"
        :key="`students-certificates-list-${certificateListKey}`"
      />
      <template slot="footer">
        <base-button link class="text-capitalize" @click="updateEnabledCertifications">
          {{ $t('common.save_enabled_certifications') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {Table, TableColumn} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import ClassroomOnGoingStudentsCertificatesList from "./ClassroomOnGoingStudentsCertificatesList";
import Student from "@/models/student";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "ClassroomOnGoingStudentsCertificates",
  components: {
    ClassroomOnGoingStudentsCertificatesList,
    Modal,
    OctoIcon,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      localStudents: [],
      selectedStudent: this.$_.cloneDeep(Student),
      showModal: false,
      certificateListKey: 0
    }
  },
  watch: {
    classroom: function (val) {
      this.localStudents = this.$_.sortBy(
        this.$_.cloneDeep(this.classroom.students),
        (student) => {
          return student?.registry?.surname || '';
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      courses: 'common/allCourses',
      certificates: 'common/certificates',
    }),
  },
  methods: {
    openModal(student) {
      this.selectedStudent = this.$_.cloneDeep(student);
      this.certificateListKey++
      this.showModal = true;
    },

    getEnabledCertificationsCodes(registry) {
      return this.$_.map(registry?.enabled_certifications, certification => this.courses[certification.id].code)
        .join(', ');
    },

    updateEnabledCertifications() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.STUDENT_ON_GOING_UPDATE_ENABLED_CERTIFICATIONS.replace('{id}', this.selectedStudent?.id),
        {certifications: this.$refs.studentEnabledCertificatesList.getEnabledCertificates()}
      )
        .then((resp) => {
          this.$set(
            this.localStudents,
            this.$_.findIndex(this.localStudents, student => student.id === this.selectedStudent.id),
            resp?.data?.data
          );

          this.showModal = false;
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
