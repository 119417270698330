<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="classroom.code" :buttons="headerButtons" @onSaveTutors="saveTutors"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.tutors') }}</h4>
          </div>
          <list-selector-component :number-items-per-page="18" :list="tutorsLocal" ref="tutorSelector">
            <template v-slot:default="slotProps">
              <div>
                {{ users[slotProps.item.id] | optional('lastname') }}
                {{ users[slotProps.item.id] | optional('firstname') }}
              </div>
            </template>
          </list-selector-component>
          <no-data-component :label="$t('didactics.no_tutors_available')" v-if="!classroom.availableTutors"/>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import NoDataComponent from "@/components/NoDataComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "ClassroomOnGoingAvailableTutorList",
  components: {
    OctoHeaderButton,
    NoDataComponent,
    ListSelectorComponent,
    ListGroupItemComponent
  },
  data() {
    return {
      tutorsLocal: [],
      headerButtons: [
        {
          label: 'save_tutors',
          onClick: 'onSaveTutors'
        },
      ]
    }
  },
  watch: {
    classroom: {
      handler(val) {
        this.tutorsLocal = this.$_.map(this.$_.cloneDeep(this.classroom.availableTutors), (item) => {
          item.selected = !!this.$_.find(this.classroom.tutors, tutor => item.id === tutor.id);
          return item;
        });
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
      users: 'common/users',
    }),
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    saveTutors() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_SYNC_TUTORS.replace('{id}', this.classroom.id),
        {tutorIds: this.$_.map(this.$refs.tutorSelector.getSelectedItems(), tutor => tutor.id)}
      )
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'tutors', value: resp?.data?.data?.tutors});
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }
  }
}
</script>

<style scoped>

</style>
