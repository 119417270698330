<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <div>{{ $t('didactics.recovery_lesson_wizard.second_step_title') }}</div>
        <div>{{ $t('didactics.recovery_lesson_wizard.second_step_subtitle') }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <list-selector-component :list="classroom.students" ref="listSelectorComponent">
          <template v-slot:default="slotProps">
            <div class="text-uppercase">
              {{ slotProps.item | optional('registry.surname') }}
              {{ slotProps.item | optional('registry.name') }}
            </div>
          </template>
        </list-selector-component>
      </div>
    </div>
    <hr>
    <div class="row justify-content-center">
      <div class="col-12">
        <h4 class="card-title">
          {{ $t('didactics.recovery_lesson_wizard.third_party_students_associated_to_course') }}
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <student-selector ref="studentSelector" :classroom="classroom"/>
      </div>
    </div>
  </div>
</template>

<script>

import StudentSelector from "@/pages/Didactics/components/StudentSelector";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import {mapGetters} from "vuex";

export default {
  name: "RecoveryLessonWizardSecondStep",
  components: {ListSelectorComponent, StudentSelector},
  methods: {
    getStudents() {
      return this.$_.concat(
        this.$refs.listSelectorComponent.getSelectedItems(),
        this.$refs.studentSelector.getSelectedStudents()
      );
    },
  },
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    })
  },
}
</script>

<style scoped>

</style>
