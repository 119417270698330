<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-6">
      <stats-card
        class="shadow mb-2"
        :title="String(classroom.lessons.length)"
        :sub-title="$t('didactics.planned_lessons')"
        type="primary"
        icon="list"
      />
    </div>
    <div class="col-12 col-md-6">
      <stats-card
        class="shadow mb-2"
        :title="String(completedLessons)"
        :sub-title="$t('didactics.completed_lessons')"
        type="success"
        icon="checked"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "@/components/Cards/StatsCard";

export default {
  name: "ClassroomOnGoingLessonsRecap",
  components: {StatsCard},
  computed: {
    ...mapGetters({
      classroom: 'current/classroomOnGoing',
    }),

    completedLessons() {
      return this.$_.filter(this.$_.cloneDeep(this.classroom.lessons), lesson => lesson.status === 'completed')?.length;
    },
  }
}
</script>

<style scoped>

</style>
