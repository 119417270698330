<template>
  <ValidationObserver ref="studentSearch">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-end">
        <div class="col-12 col-md-5">
          <ValidationProvider name="firstname" rules="min:3" v-slot="{ passed, failed, errors }">
            <base-input
              v-model="name"
              class="flex-grow-1"
              type="text"
              :label="$t('fields.firstname')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-5">
          <ValidationProvider name="lastname" rules="min:3" v-slot="{ passed, failed, errors }">
            <base-input
              v-model="surname"
              class="flex-grow-1"
              type="text"
              :label="$t('fields.lastname')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-2">
          <base-button icon link class="mb-2" @click="validate">
            <octo-icon icon="magnifying-glass"/>
          </base-button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <card class="card-plain" body-classes="standard-card-body" header-classes="p-2 pb-0" v-if="studentKey > 0">
            <div slot="header">
              <slot name="header">
                <h4 class="card-title mb-0">
                  {{ $t('common.results_number') }}:
                  <label-theme-component class="font-weight-400">{{ foundStudents.length }}</label-theme-component>
                </h4>
              </slot>
            </div>
            <ul class="list-group list-group-flush max-height-list">
              <list-group-item-component
                v-for="(student, index) in foundStudents"
                v-bind:key="`found-student-${index}`"
              >
                <template slot="label">
                  <label-theme-component>{{ student.id | udid }}:</label-theme-component>
                  <div class="ml-1 text-uppercase">
                    {{ student | optional('registry.surname') }} {{ student | optional('registry.name') }}
                  </div>
                </template>
                <template slot="value">
                  <base-button icon link class="ml-auto text-capitalize mb-0" @click="addStudent(student)">
                    <octo-icon icon="add"/>
                  </base-button>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-8">
          <card class="card-plain" body-classes="standard-card-body" header-classes="p-2 pb-0"
                v-if="selectedStudents.length > 0">
            <div slot="header">
              <slot name="header">
                <h4 class="card-title mb-0">{{ $t('didactics.selected_students') }}:</h4>
              </slot>
            </div>
            <ul class="list-group list-group-flush">
              <list-group-item-component v-for="student in selectedStudents" v-bind:key="`students-${student.id}`">
                <template slot="label">
                  <label-theme-component>{{ student.id | udid }}:</label-theme-component>
                  <div class="ml-1 text-uppercase">
                    {{ student | optional('registry.surname') }} {{ student | optional('registry.name') }}
                  </div>
                </template>
                <template slot="value">
                  <base-button icon link class="ml-auto text-capitalize mb-0" @click="deselectStudent(student)">
                    <octo-icon icon="wrong"/>
                  </base-button>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Classroom from "@/models/classroom";

export default {
  name: "StudentSelector",
  components: {LabelThemeComponent, ListGroupItemComponent, OctoIcon},
  data() {
    return {
      name: '',
      surname: '',
      endpoints: endpoints,
      foundStudents: [],
      selectedStudents: [],
      studentKey: 0
    }
  },
  props: {
    classroom: {
      type: Object,
      default: () => _.cloneDeep(Classroom)
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.studentSearch.validate()
          .then((success) => {
            if (success) {
              this.$fullLoading.show();

              this.$api.post(endpoints.STUDENT_SEARCH, {name: this.name, surname: this.surname})
                .then((resp) => {
                  this.foundStudents = this.$_.filter(resp.data, item => {
                    return !this.$_.find(this.classroom.students, student => student.id === item.id);
                  });
                  this.studentKey++;
                  resolve();
                })
                .catch((e) => {
                  this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
                  reject(e);
                })
                .finally(() => {
                  this.$fullLoading.hide();
                });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addStudent(student) {
      if (!this.$_.find(this.selectedStudents, selectedStudent => selectedStudent.id === student.id)) {
        this.selectedStudents.push(student);
      } else {
        this.$notify({type: 'warning', message: this.$t('notifications.student_already_selected')});
      }
    },

    deselectStudent(student) {
      this.selectedStudents.splice(
        this.$_.findIndex(this.selectedStudents, selectedStudent => selectedStudent.id === student.id),
        1
      );
    },

    getSelectedStudents: function () {
      return this.$_.cloneDeep(this.selectedStudents || []);
    },
  },
}
</script>

<style scoped>
.max-height-list {
  max-height: 400px;
  overflow: auto;
}
</style>
