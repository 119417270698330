const LessonStatuses = {
  scheduled: {
    value: 'scheduled',
  },
  completed: {
    value: 'completed',
  },
  cancelled: {
    value: 'cancelled',
  },
  holiday: {
    value: 'holiday',
  }

}

export default LessonStatuses
