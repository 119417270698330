<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <octo-header-button :title="classroom.code" :buttons="headerButtons" @onSaveProgram="saveProgram"/>
        </div>
      </div>
      <div
        class="row mb-3"
        v-for="(module, moduleIndex) in educationalProgram"
        v-bind:key="`module-${moduleIndex}`"
      >
        <div class="col-md-12">
          <card body-classes="standard-card-body" class="shadow mb-0">
            <div slot="header">
              <h4 class="card-title">
                <label-theme-component>{{ module.code }}:</label-theme-component>
                {{ module.title }}
              </h4>
            </div>
            <ul class="list-group list-group-flush">
              <list-group-item-component v-for="(subject, subjectIndex) in module.subjects"
                                         v-bind:key="`subject-${subjectIndex}`">
                <template slot="label">
                  {{ subject.title }}
                </template>
                <template slot="value">
                  <div>
                    <el-tag
                      closable
                      v-if="keyedLessons[lessonId]"
                      v-for="(lessonId, index) in subject.lessons"
                      :key="`lesson-${index}`"
                      @close="subject.lessons.splice(index, 1)"
                    >
                      {{ keyedLessons[lessonId].code }}
                    </el-tag>
                    <base-button class="mx-1" size="sm" link icon @click="openLessonsModal(moduleIndex, subjectIndex)">
                      <octo-icon icon="edit"/>
                    </base-button>
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
      </div>
    </div>
    <modal bodyClasses="p-1" :show.sync="showModal" centered modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title text-uppercase">{{ $t('fields.lessons') }}</h5>
      </template>
      <list-selector-component
        ref="listSelectorComponent"
        :number-items-per-page="8"
        :list="selectorItems"
        :key="`selector-${modalKey}`"
      >
        <template v-slot:default="slotProps">
          {{ slotProps.item.code }}
        </template>
      </list-selector-component>
      <template slot="footer">
        <base-button link class="text-uppercase" @click="saveLessonsInSubject">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>

import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import {Table, TableColumn, Select, Option, Tag} from "element-ui";
import Badge from "@/components/Badge";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {permissionService} from "@/util/permission-service";
import LessonForm from "@/pages/Didactics/forms/LessonForm";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";

export default {
  name: "ClassroomOnGoingProgram",
  components: {
    ListSelectorComponent,
    Modal,
    ListGroupItemComponent,
    LabelThemeComponent,
    LessonForm,
    OctoIcon,
    Badge,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
  },
  data() {
    return {
      selectorItems: [],
      educationalProgram: [],
      keyedLessons: {},
      showModal: false,
      modalKey: 0,
      currentModuleIndex: 0,
      currentSubjectIndex: 0,
    }
  },
  watch: {
    classroom: {
      handler(val) {
        this.educationalProgram = this.$_.cloneDeep(this.classroom.educational_program);
        this.keyedLessons = this.$_.keyBy(this.$_.cloneDeep(this.classroom.lessons), 'id');
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      classroom: 'current/classroomOnGoing',
    }),

    headerButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.TUTOR_ROLE])) {
        buttons.push({
          label: 'save_program',
          onClick: 'onSaveProgram'
        });
      }

      return buttons;
    }
  },
  methods: {
    ...mapMutations({
      setPartialClassroomOnGoing: 'current/setPartialClassroomOnGoing'
    }),

    saveProgram: async function () {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.CLASSROOM_UPDATE_ON_GOING_PROGRAM.replace('{id}', this.classroom.id),
        {educational_program: this.educationalProgram}
      )
        .then((resp) => {
          this.setPartialClassroomOnGoing({key: 'educational_program', value: resp?.data?.data?.educational_program});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    openLessonsModal(moduleIndex, subjectIndex) {
      this.currentModuleIndex = moduleIndex;
      this.currentSubjectIndex = subjectIndex;

      this.selectorItems = this.$_.map(this.$_.cloneDeep(this.classroom.lessons), lesson => {
        lesson.selected = !!this.$_.find(
          this.educationalProgram?.[moduleIndex]?.subjects?.[subjectIndex]?.lessons || [],
          lessonId => lessonId === lesson.id
        );
        return lesson;
      });

      this.modalKey++;
      this.showModal = true;
    },

    saveLessonsInSubject() {
      const selectedItems = this.$refs.listSelectorComponent.getSelectedItems();

      this.$set(
        this.educationalProgram[this.currentModuleIndex].subjects[this.currentSubjectIndex],
        'lessons',
        this.$_.map(selectedItems, item => item.id)
      );

      this.showModal = false;
    }
  }
}
</script>

<style scoped>
</style>
